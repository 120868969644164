import { SITE_PRIMARY_COLOR } from "@api/constants";
import { FC } from "react";

const GlobeIcon: FC = () => {
  return (
    <>
      <span className="GlobeIcon" />
      {/* language=CSS */}
      <style jsx>
        {`
          .GlobeIcon {
            font-family: "Font Awesome 5 Free-Solid-900";
            color: ${SITE_PRIMARY_COLOR};
            padding-top: 2px;
            line-height: 30px;
          }

          .GlobeIcon::before {
            /*noinspection CssInvalidPropertyValue*/
            content: "\f0ac";
          }
        `}
      </style>
    </>
  );
};

export default GlobeIcon;
