import { SITE_PRIMARY_COLOR } from "@api/constants";
import { FC } from "react";

const MailIcon: FC = () => {
  return (
    <>
      <span className="MailIcon" />
      {/* language=CSS */}
      <style jsx>
        {`
          .MailIcon {
            font-family: "Font Awesome 5 Free-Solid-900";
            color: ${SITE_PRIMARY_COLOR};
            padding-top: 2px;
            line-height: 30px;
          }

          .MailIcon::before {
            /*noinspection CssInvalidPropertyValue*/
            content: "\f0e0";
          }
        `}
      </style>
    </>
  );
};

export default MailIcon;
