import { SITE_SECONDARY_COLOR } from "@api/constants";
import Link from "next/link";
import { FC, useContext } from "react";

import FaIcon from "@components/FaIcon";
import { IconType } from "@components/FaIcon/FaIcon";
import UserContext from "@components/UserContext";

const PrimaryNavigation: FC = () => {
  const { customer } = useContext(UserContext);
  return (
    <>
      <ul className="PrimaryNavigation">
        {customer && !customer.guest ? (
          <>
            <li>
              <Link href="/courses/purchased">
                <a>My Courses</a>
              </Link>
            </li>
          </>
        ) : null}
        <li>
          <Link href="/course/catalog">
            <a>Course Catalog</a>
          </Link>
        </li>
        {!process.env.NEXT_PUBLIC_HIDE_CART && (
          <li>
            <Link href="/cart">
              <a>
                <FaIcon type={IconType.Cart} color={SITE_SECONDARY_COLOR} />
              </a>
            </Link>
          </li>
        )}
      </ul>
      {/* language=CSS */}
      <style jsx>
        {`
          .PrimaryNavigation {
            margin: 0;
            list-style-type: none;
          }

          .PrimaryNavigation li {
            display: inline-block;
          }

          a {
            text-decoration: none;
            text-transform: uppercase;
            color: #a8b6be;
            font-size: 0.9rem;
            white-space: nowrap;
            padding: 0 0 0 40px;
          }
        `}
      </style>
    </>
  );
};

export default PrimaryNavigation;
